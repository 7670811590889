.containerTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
