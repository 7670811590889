.otp-modal-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.otp-modal-centered .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0;
}