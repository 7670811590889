.container {
	cursor: pointer;
	position: absolute;
	display: flex;
	justify-content: flex-end;
	flex-flow: column nowrap;
	height: 30px;
	bottom: 0;
	width: 100%;
}
.bar {
	position: relative;
	transition: all 100ms linear;
	width: 0;
	background-color: aliceblue;
	height: 5px;
	border-radius: 0px 5px 5px 0px;
}
.barBackground {
	transition: all 100ms linear;
	background-color: rgba(240, 248, 255, 0.442);
	height: 5px;
}
.activeBar {
	height: 15px;
}
.formatTime {
	margin-bottom: 2px;
	text-align: center;
	position: absolute;
	height: 100%;
	width: 50px;
	bottom: 0;
	font-size: 70;
	color: black;
}
