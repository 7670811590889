.ratingLabel {
  position: relative;
}

.ratingLabel input[type="radio"] {
  /* display: none; */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.ratingPara {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  max-width: 300px;
  width: 100%;
}

.ratingLabel .ratingStar {
  cursor: pointer;
}

.ratingWrap {
  display: flex;
  align-items: center;
}

.ratingWrap p {
  margin: 4px 0 0 10px;
  line-height: 1;
  font-weight: bold;
}
.ratingWrap label {
  margin: 0;
}
