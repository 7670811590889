.checkbox_label_wrapper {
  display: flex;
}

.checkbox-wrapper {
  display: flex;
  align-self: center;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
}

.new-line {
  white-space: pre-line;
}