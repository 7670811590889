.dropbox-button {
    background: linear-gradient(to bottom, #fcfcfc 0%, #f5f5f5 100%);
    color: #636363;
    font-family: "Lucida Grande", "Segoe UI", "Tahoma", "Helvetica Neue", "Helvetica", sans-serif;
    margin-top: 13px;
    margin-bottom: 13px;
    padding: 6px 0px;
    border: 1px solid #ebebeb;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    width: 100%;
    max-width: 100%;
}

.dropbox-button img {
    width: 25px;
    height: 25px;
}
