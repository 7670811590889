.trends-loading svt {
    vertical-align: middle;
}

.trends-loading {
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
}

.tmap {
    height: 1000px;
}

.rdt input {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: hsl(0,0%,20%);
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding-top: 3px;
    padding-right: 7px;
    padding-left: 7px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
}

.ending-month {
    margin-top: 5px;    
}