.trackWrap {
}

.trackWrap {
	margin: 40px auto 0 auto;
}
.trackWrap:first-of-type {
	margin: 0 auto;
}
.favoriteLabel {
	position: relative;

	display: flex;
	align-items: center;
	max-width: 96px;
	width: 100%;
	color: #000a60;
	background-color: transparent;
	background-image: none;
	/* border-color: #000a60; */
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	margin: 0;
	user-select: none;
	border: 1px solid #000a60;
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	/* line-height: 1.5; */
	border-radius: 2px;
	transition: color 0.15s;
}

.favoriteLabel span,
.favoriteLabelClicked span {
	margin: 0 5px 0 0;
}
.favoriteClicked {
	color: #fff;
}

.nameContainer {
	display: flex;
	justify-content: space-between;
}

.trackButtonContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 96px;
	margin: 0 0 0 20px;
	width: 100%;
}

.trackButtonContainer .btn {
	max-width: 96px;
	width: 100%;
}

.btn-group {
	max-width: 96px;
	width: 100%;
}
.favoriteLabel input[type="radio"],
.favoriteLabelClicked input[type="radio"] {
	/* display: none; */
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	cursor: pointer;
	width: 100%;
	height: 100%;
}

.favoriteLabelClicked {
	position: relative;
	display: flex;
	margin: 0;
	align-items: center;
	max-width: 96px;
	width: 100%;
	background-color: #000a60;
	background-image: none;
	/* border-color: #000a60; */
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;

	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	/* line-height: 1.5; */
	border-radius: 2px;
	transition: color 0.15s;
}
.favoriteLabel .favoriteIcon,
.favoriteLabelClicked .favoriteIcon {
	cursor: pointer;
}

.playerWrap {
	display: flex;
	align-items: center;
	/* max-width: 430px; */
	width: 100%;
	margin: 20px 0 0 0;
}

/* .playerWrap audio {
  position: relative;
  right: 20px;
} */
/* .playerWrap > div {
  background: #fff;
}
audio::-webkit-media-controls-panel {
  background: #fff;
} */

input[pseudo="-webkit-media-controls-timeline" i]:-internal-track-segment-highlight-before {
	background: red !important;
}
.playerWrap audio {
	width: 100%;
}

.helperWrap {
	display: flex;
	max-width: 100%;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.helperWrap button {
	/* max-width: 96px; */
}

.favoriteWrap {
	display: flex;
	align-items: center;
}

.favoriteWrap p {
	margin: 4px 0 0 10px;
	line-height: 1;
	font-weight: bold;
}
.favoriteWrap label {
	margin: 0;
}

@media (max-width: 520px) {
	.trackButtonContainer {
		flex-direction: row;
		.btn {
			margin: 10px auto 0 auto;
		}
	}
	.commentContainer {
		flex-direction: column;
		.helperWrap {
			a {
				margin: 10px 0 10px 0;
			}
		}
	}
}
