.landing-page-layout-two {
	#canvas-parent canvas {
		position: absolute;
		bottom: 0;
	}
	.back-image {
		z-index: 1;
		top: 0px;
		content: "";
		position: fixed;
		left: 0px;
		width: 100%;
		height: 110%;
	}
	.background-image-present {
		filter: blur(10px);
	}
}

.bodymain {
	height: 100%;
	padding: 0px 0;
	z-index: 2;
	position: relative;
}

.layout-header {
	width: 100%;
	min-height: 100px;
	position: relative;
	padding-top: 50px;
}

.copyright {
	div {
		background-color: #00000045;
		display: inline-block;
		margin-right: 10%;
		margin-left: 10%;
	}
}

.layout-links img {
	display: block;
	position: absolute;
	object-fit: cover;
	border-radius: 40px;
	width: 80px;
	height: 80px;
	right: 50%;
	transform: translate(50%,-50%);
	margin-left: calc(50% - 100px);
	margin-top: -25px;
}

.layoutTwofeaturedLink{
	padding-top: 1%;
	padding-bottom: 1%;
	padding-right: 35%;
	padding-left: 35%;
}

.featuredLinkBtn {
	width: 100%;
	border: 0px;
	padding: 15px !important;
	font-size: 15px !important;
	white-space: normal !important;
	word-wrap: break-word !important;
}

.featuredLinkBtn:hover {
	color: var(--hover-color) !important;
	background-color: var(--hover-bg-color) !important;
	border: var(--hover-border) !important; 
	opacity: var(--hover-opacity);
}

.featuredLinkBtn:focus, .featuredLinkBtn.focus {
	box-shadow: 0 0 0 0.2rem var(--focus-box-shadow); 
}

.rounded-button {
	border-radius: 50px !important;
}

.semi-rounded-button {
	border-radius: 20px !important;
}

.squared-button {
	border-radius: 0px !important;
}
