
.Player_App{
  button {
    background: OrangeRed;
    color: white;
    border: none;
    min-width: 60px;
    height: 60px;
    border-radius: 100%;
    outline: none;
    text-transform: uppercase;
    transition: all 0.2s ease;
    margin-right: 20px;
    cursor: pointer;
  }

  button:hover {
    background: #d63d05;
  }

  .playlist {
    text-align: left;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .playlist-item {
    color: #444;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 1em;
  }

  .playlist-item.selected {
    font-weight: bold;
  }

  .playlist-item:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  .controls {
    text-align: left;
    padding: 1em;
    display: flex;
    align-items: center;

    .range{
      max-width: 200px;
    }
  }

}
